import moment from "moment";
import { getMonthDiff, checkgreaterthanEol } from '../components/utils';

export function calculation(item) {

    let interestProfit = 0;
    if ((item.founding && item.client_interest) && (item.client_interest > item.founding)) {
        interestProfit = Math.round((parseFloat(item.client_interest) - parseFloat(item.founding)));
    }
    item.interest_profit = interestProfit;
    let commission_amount = 0;
    if ((item.sum_purchase_value && item.commision_to_partner_percentage)) {
        commission_amount = (parseFloat(item.sum_purchase_value) * parseFloat(item.commision_to_partner_percentage)) / 100;
        item.commision_partner_amount = Math.round(commission_amount);
    }

    let EOLvalueamt = 0;
    if ((item.sum_purchase_value && item.EOL_percentage)) {
        EOLvalueamt = (parseFloat(item.sum_purchase_value) * parseFloat(item.EOL_percentage)) / 100;
        item.EOL_value_amt = Math.round(EOLvalueamt);
    }
    let montlypaymentvalue = 0;
    if ((item.sum_purchase_value && item.montly_payment_ratio)) {
        montlypaymentvalue = (parseFloat(item.sum_purchase_value) * parseFloat(item.montly_payment_ratio)) / 100;
        item.montly_payment_rent_or_lease = Math.round(montlypaymentvalue);
    } else {
        item.montly_payment_rent_or_lease = 0;
    }
    if (item.eol_date && item.notice_period_before_EOL) {
        let noticeperiodEOLDate = moment(item.eol_date).subtract(item.notice_period_before_EOL, 'M').subtract(1, "days").format('YYYY-MM-DD');
        item.latest_notice_day = noticeperiodEOLDate;
    } else {
        item.latest_notice_day = "";
    }
    if (item.latest_notice_day && item.notice_before_notice_month) {
        let noticeperiodEOLMonth = moment(item.latest_notice_day).subtract(item.notice_before_notice_month, 'M').subtract(1, "days").format('YYYY-MM-DD');
        item.notice_before_notice_day = noticeperiodEOLMonth;
    } else {
        item.notice_before_notice_day = "";
    }
    let add_purchase_value = 0;
    if ((item.sum_purchase_value && item.montly_payment_ratio && item.no_of_month_lease && item.EOL_value_amt)) {
        add_purchase_value = ((parseFloat(item.sum_purchase_value)) * (parseFloat(item.montly_payment_ratio) / 100)) * parseFloat(item.no_of_month_lease) + parseInt(item.EOL_value_amt);
        item.add_to_purchase_value = Math.round(add_purchase_value);
    }
    return item
}

export function getAgreementStatus1(values) {
    if (values.lease_start_date && values.no_of_month_lease) {
        let noOfMonthLease = values.no_of_month_lease;
        let leaseStartDate = values.lease_start_date;
        // console.log("leaseStartDate", leaseStartDate)
        // console.log("noOfMonthLease", noOfMonthLease)
        // return
        let eoldate = moment(leaseStartDate).add(noOfMonthLease, 'M').subtract(1, "days").format('YYYY-MM-DD')
        // console.log(leaseStartDate, "eoldate", eoldate)
        let startdate = leaseStartDate
        let today = moment();
        let prevMonth = moment(startdate).subtract(3, 'months').format('YYYY-MM-DD')
        let endingprevMonth = moment(eoldate).subtract(3, 'months').format('YYYY-MM-DD')
        let interim_agreement = moment(today).isBetween(prevMonth, startdate)
        let ending_agreement = moment(today).isBetween(endingprevMonth, eoldate)
        let ongoing_agreement = moment(today).isAfter(startdate);
        let eolddateBefore = moment(today).isBefore(eoldate);
        let eolddateAfter = moment(today).isAfter(eoldate);
        let Closed_agreement = moment(today).isAfter(eoldate);
        let status = 'PREPARE'
        if (interim_agreement) {
            status = 'INTERIM'
        } else if (ending_agreement && ongoing_agreement && eolddateBefore) {
            status = 'ENDING'
        } else if (ongoing_agreement && eolddateBefore) {
            status = 'ONGOING'
        } else if (Closed_agreement && eolddateAfter) {
            status = 'CLOSED'
        }
        return {
            eoldate, status
        }
        // values.eol_date = (eoldate)
        // values.ag_status = status
        // setValues({ ...values })
    }
}

export function getAgreementStatus(values) {
    if (values.lease_start_date && values.no_of_month_lease) {
        let noOfMonthLease = values.no_of_month_lease;
        let leaseStartDate = values.lease_start_date;
        // return
        let eoldate = moment(leaseStartDate).add(noOfMonthLease, 'M').subtract(1, "days").format('YYYY-MM-DD')
        // console.log(leaseStartDate, "eoldate", eoldate)
        let today = moment().format('YYYY-MM-DD')
        const monthDiff = getMonthDiff(eoldate);
        const leasestartmonthDiff = getMonthDiff(leaseStartDate);
        const ischeckgreaterthanEol = checkgreaterthanEol(eoldate)
        let startdate = leaseStartDate;
        let prevMonth = moment(startdate).subtract(3, 'months').format('YYYY-MM-DD')
        let interim_agreement = moment(today).isBetween(prevMonth, startdate);
        let checkDateBetweenLease = moment(today).isBetween(startdate, eoldate);
        let ending_agreement = moment(values.eol_date).diff(today, 'days');
        let status = values.ag_status;

        // console.log(values,"ischeckgreaterthanEol",ischeckgreaterthanEol)
        // console.log("is validddd",moment(values.lease_start_date).isValid())

        // if (monthDiff >= 3) {
        //     status = 'ONGOING'
        // }
        // console.log("prevMonth", prevMonth)
        // console.log("startdate", startdate)
        // console.log("end date", eoldate)
        // console.log("monthDiff", monthDiff)
        // console.log("interim_agreement", interim_agreement)
        // console.log("checkDateBetweenLease", checkDateBetweenLease)
        if (!ischeckgreaterthanEol && status !== 'CLOSED' && status !== 'EXTENDED') {
            // status = 'PREPARE'
            status = 'INTERIM'
            // console.log("ag_status",monthDiff)
            if (interim_agreement) {
                // status = 'INTERIM'
            } else if (checkDateBetweenLease && monthDiff >= 3) {
                status = 'ONGOING'
            } else if ((monthDiff > 0 && monthDiff <= 3) || (ending_agreement > 0 && ending_agreement <= 30)) {
                status = 'ENDING'
            }
            // else if (monthDiff < 1) {
            //  status = 'CLOSED'
            // }
        } else if (ischeckgreaterthanEol && status !== 'EXTENDED') {
            if (status !== 'CLOSED') {
                status = 'EXTENDED'
            }
        }
        return {
            eoldate, status
        }
    }
}

export function getInventoryStatus(end_of_lifecycle) {
    if (end_of_lifecycle) {
        const monthDiff = getMonthDiff(end_of_lifecycle);
        let status = ''
        if (monthDiff > 0 && monthDiff <= 3) {
            status = 'ENDING'
        }
        return {
            status
        }
    }
}

export function SVformatter(v) {
    return v ? new Number(v).toLocaleString("sv-SE", { minimumFractionDigits: 0 }) : 0;
}

export function AgDifferenceValues(params) {
    let sum_purchase_value = params && params.sum_purchase_value ? params?.sum_purchase_value : 0;
    let sum_purchase_value_1 = params && params.sum_purchase_value_1 ? params?.sum_purchase_value_1 : 0;
    return getDifference(sum_purchase_value_1, sum_purchase_value)
}

function getDifference(a, b) {
    return Math.abs(a - b);
}