/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { shallowEqual, useSelector } from 'react-redux'
import { shadowToken } from '../../../helpers/components/utils'
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import * as auths from '../../../../app/modules/auth/redux/AuthRedux';


// console.log("shadowToken", shadowToken())

export default function AsideMenuMain() {
  const intl = useIntl()
  const dispatch = useDispatch();
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (data && data.id && data.role) {
      setUserInfo(data)
    }
  }, [data])

  const stopShadowing = async (id) => {
    localStorage.setItem("shadowToken", '')
    localStorage.setItem("auth_token", localStorage.getItem("mainToken"))
    setTimeout(() => {
      dispatch(auths.actions.login(localStorage.getItem("mainToken")))
    }, 3000);

  }

  return (
    <>
      <AsideMenuItem
        to='/overview'
        icon='/media/logos/overview.png'
        title={'Overview'}
        fontIcon='bi-app-indicator'
        clickable={true}
      />
      <AsideMenuItem
        to='/agreements'
        icon='/media/logos/agreements.png'
        title='Agreements'
        fontIcon='bi-layers'
        clickable={true}
      />
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') &&
        <AsideMenuItem
          to='/assetsnew'
          icon='/media/logos/assets.png'
          title='Assets'
          fontIcon='bi-layers'
          clickable={true}
        />
      }
      {
        (userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user' || userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') &&
        <AsideMenuItem
          to='/assets'
          icon='/media/logos/assets.png'
          title='Assets'
          fontIcon='bi-layers'
          clickable={true}
        />
      }
      {/* <div className="menu-item-bottom"> */}
{/* 
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user' || userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') &&
        <AsideMenuItem
          to='/assettypes'
          // icon='/media/logos/admin.png'
          title='Asset Types'
          fontIcon='bi-layers'
          clickable={false}
        />
      } */}

      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') && <AsideMenuItem
          to='/partners'
          title='Partners'
          fontIcon='bi-layers'
          clickable={false}
        // icon={'/media/logos/partner.png'}

        />
      }
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user' || userInfo?.role?.name === 'admin_user') &&
        <AsideMenuItem
          to='/clients'
          title='Clients'
          fontIcon='bi-layers'
          clickable={false}
        // icon={'/media/logos/client.png'}

        />
      }
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') && <AsideMenuItem
          to='/banks'
          title='Bank'
          fontIcon='bi-layers'
          clickable={false}
        // icon={'/media/logos/bank.png'}
        />
      }
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') && <AsideMenuItem
          to='/suppliers'
          title='Suppliers'
          fontIcon='bi-layers'
          clickable={false}
        // icon={'/media/logos/supplier.png'}

        />
      }
      {
        ((userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') || (userInfo?.role?.name === 'master_admin') || userInfo?.role?.name === 'admin_user') &&
        <>
          <AsideMenuItem
            to='/overview-inventory'
            title={'Overview Inventory'}
            fontIcon='bi-app-indicator'
            hasBullet={true}
            icon={(userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') ? '/media/logos/overviewInventory.svg' : ''}
          />
          <AsideMenuItem
            to='/inventory'
            title={'Inventory'}
            fontIcon='bi-app-indicator'
            hasBullet={true}
            icon={(userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') ? '/media/logos/inventory_logo11.png' : ''}
          />
        </>
      }
      {
        (userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') &&
        <AsideMenuItem
          to={`/clientsusers/${userInfo?.client.id}/${userInfo?.id}`}
          title='Users'
          fontIcon='bi-layers'
          clickable={false}
          icon={'/media/logos/admin.png'}

        />
      }
      {
        (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') &&
        <AsideMenuItem
          to={`/partnersusers/${userInfo?.partner.id}/${userInfo?.id}`}
          title='Users'
          fontIcon='bi-layers'
          clickable={false}
          icon={'/media/logos/admin.png'}
        />
      }
      {/* {
        (userInfo?.role?.name !== 'client_admin' || userInfo?.role?.name !== 'client_user') &&
        <AsideMenuItem
          to=''
          // icon='/media/logos/admin.png'
          icon={(userInfo?.role?.name !== 'client_admin' || userInfo?.role?.name !== 'client_user') ? '/media/logos/admin.png' : ''}
          clickable={false}
        />
      } */}
      {
        (userInfo?.role?.name === 'master_admin') &&
        <AsideMenuItem
          to={`/adminusers/${userInfo?.id}`}
          title='Users'
          fontIcon='bi-layers'
          // icon={(userInfo?.role?.name !== 'client_admin' || userInfo?.role?.name !== 'client_user') ? '/media/logos/admin.png' : ''}
          clickable={false}
        />
      }
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') &&
        <AsideMenuItem
          to={`/activity`}
          title='User activity'
          fontIcon='bi-layers'
          clickable={false}
        // icon={'/media/logos/user-activity.png'}
        />
      }
      {
        (userInfo?.role?.name === 'supplier_admin' || userInfo?.role?.name === 'supplier_user') &&
        <AsideMenuItem
          to={`/supplierusers/${userInfo?.supplier.id}/${userInfo?.id}`}
          title='Users'
          fontIcon='bi-layers'
          clickable={false}
          icon={'/media/logos/admin.png'}

        />
      }
      <AsideMenuItem
        to={`/profile`}
        title='Profile'
        fontIcon='bi-layers'
        clickable={false}
      />
      {
        shadowToken() &&
        <Button
          variant="secondary"
          color="danger"
          className="cancelBtn"
          onClick={() => stopShadowing()}>
          Back to Admin
      </Button>
      }
      {/* {
        ((userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') || (userInfo?.role?.name === 'master_admin')) &&

        <AsideMenuItemWithSub
          to='/overview'
          title={'Leased'}
          fontIcon='bi-app-indicator'
          hasBullet={false}
        >
          <AsideMenuItem
            to='/leased-overview'
            title={'overview'}
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/leased-aggrement'
            title={'Agreements'}
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/leased-assets'
            title={'Assets'}
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/ownpartners'
            title='Partners'
            fontIcon='bi-layers'
            clickable={false}
          />
        </AsideMenuItemWithSub>
      } */}
      {/* </div> */}
    </>
  )
}
