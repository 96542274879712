/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import AgreementTable from '../../modules/agreements/index';
import { useDispatch, useSelector } from "react-redux";
import { directUSAPICall, SVformatter, getAgValuesFormat } from '../../../_metronic/helpers/components/utils';
import PageLoader from "../../../_metronic/helpers/components/PageLoader";
import _ from "lodash";
// import { Column, G2 } from '@ant-design/charts';
import { Redirect, useLocation } from 'react-router-dom'
import * as auth from '../../modules/auth/redux/AuthRedux';
import FilterSelection from '../../../_metronic/helpers/components/Filter';
import { ClientAddessList } from '../../modules/client/redux/ClientCrud'
import { FILTER_AG_STATUS, FILTER_ASSET_STATUS } from "../../../_metronic/helpers/components/constant";
import moment from "moment";
import { getLists } from './redux/Crud';

import { Column, Pie } from '@ant-design/plots';

const dashboardfields = [
  'ag_status',
  'client_name',
  'heading',
  'agrement_info_no',
  'eol_date',
  'start_date',
  'no_of_month_lease',
  'sum_purchase_value',
  'lease_start_date',
  'type_of_product',
  'sum_purchase_value',
  'montly_payment_rent_or_lease'
]


const formatText = (arg) => {
  return `${SVformatter(arg.valueText) + ' Kr'} (${arg.percentText})`;
}

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'B';
  } else if (number > 1000000) {
    return (number / 1000000).toString() + 'Mkr';
  } else if (number > 1000) {
    return (number / 1000).toString() + 'Tkr';
  } else {
    return number.toString();
  }
}

// const pointClickHandler = (e) => {
//   window.open(`/assetview/${e.target.data.name}`, '_blank', 'noreferrer');
// }
// const onPointHoverChanged = (e) => {
//   if (e.target.isHovered()) {
//     e.element.style.cursor = "pointer"
//   } else {
//     e.element.style.cursor = "auto"
//   }
// }



const DashboardPage = ({
  userTypes,
  agreementList,
  getValues,
  values,
  agreementDetails,
  setSelectedRows,
  selectedRows,
  totalFinanceAggTitle,
  agreementListTemp,
  setLoading,
  upcomingrents,
  top3Products,
  productGroup,
  upComingBarChart,
  callAssetsList,
  productGroupconfig,
  upComingBarChartconfig,
  setSelected,
  pieconfig
}) => (
  <>
    {/* begin::Row */}

    <div className='row gy-5 g-xl-8 overview'>
      <div className='col-lg-4 div1'>
        <div className={`card stat detail`}>
          {/* begin::Header */}
          <div className={`card-header border-0 bg-danger`}>
            <h3 className='card-title text-bold MuiTableCell-head'>Overview</h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body p-0'>
            {/* begin::Chart */}
            <div
              className={`mixed-widget-2-chart card-rounded-bottom bg-danger`}
            ></div>
            {/* end::Chart */}
            {/* begin::Stats */}
            <div className='card-p position-relative card-p1'>
              {/* begin::Row */}
              <div className="table-responsive">
                {/* begin::Table */}
                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                  {/* begin::Table head */}
                  <tbody>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Number of Agreements</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.noofagreements}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Next ending date</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.nextEndingDate || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Monthly rent</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.monthyRent + " kr" || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Quartely rent</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.QuarterlyRent + " kr" || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Yearly rent</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.YearlyRent + " kr" || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Asset value</td>
                      <td className="p-0 w-5px title-val" >{agreementDetails?.totalValue + " kr" || ''}</td>
                    </tr>
                  </tbody>
                </table>
                {
                  userTypes.isAdmin && <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    {/* begin::Table head */}
                    <tbody>
                      <tr className="border-0">
                        <td className="p-0 w-5px title" >Interim report</td>
                        <td className="p-0 w-5px title-val" >{agreementDetails?.interimincome ? agreementDetails?.interimincome + " kr" : 0}</td>
                      </tr>
                      <tr className="border-0">
                        <td className="p-0 w-5px title" >Extension rents per quarter</td>
                        <td className="p-0 w-5px title-val" >{agreementDetails?.extendedQuarterRent ? agreementDetails?.extendedQuarterRent + " kr" : 0}</td>
                      </tr>
                      <tr className="border-0">
                        <td className="p-0 w-5px title" >Number of contract expiring / quarter</td>
                        <td className="p-0 w-5px title-val" >{agreementDetails?.noofLeaseExpireQuarter ? agreementDetails?.noofLeaseExpireQuarter : 0}</td>
                      </tr>
                      <tr className="border-0">
                        <td className="p-0 w-5px title" >EOL income/quarter</td>
                        <td className="p-0 w-5px title-val" >{agreementDetails?.extendedEOLQuarterRent ? agreementDetails?.extendedEOLQuarterRent + " kr" : 0}</td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>


        <div className="card stat">
          <div className={`card-header border-0 bg-danger`}>
            <h3 className='card-title text-bold MuiTableCell-head'>{'Top 3 product groups'}</h3>
          </div>
          <div className='card-body'>
            <div style={{ height: 280 }}>
              {
                top3Products?.length > 0 ? <Pie {...pieconfig} /> : <div><span>No records found.</span></div>
              }
            </div>
          </div>
        </div>


      </div>
      <div className='col-lg-8 div2'>
        <AgreementTable
          values_filter={values}
          agreementList_Temp={agreementListTemp}
          setSelectedRows={setSelectedRows}
          dashBoardAggList={agreementList}
          callAssetsList={callAssetsList}
          dashboardfields={dashboardfields}
          getValues={getValues}
          setOverviewLoading={setLoading}
          upcomingrents={upcomingrents}
          selectedRows={selectedRows}
          setSelectedAg={setSelected}
          currentuserTypes={userTypes}
          currentpage="overview"
        />
      </div>
    </div>
    <div className='row gy-5 g-xl-8 overview bottom'>
      <div className='col-lg-4 div1'>
        {
          <div>
            <div className="card stat ">
              <div className={`card-header border-0 bg-danger`}>
                <h3 className='card-title text-bold MuiTableCell-head'>{totalFinanceAggTitle}</h3>
              </div>
              <div className='card-body'>
                <div>
                  {
                    upComingBarChart?.length > 0 ? <Column {...upComingBarChartconfig} /> :
                      <div><span>No records found.</span></div>
                  }

                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className='col-lg-8 div2'>
        {
          <div>
            <div className="card stat ">

              <div className={`card-header border-0 bg-danger`}>
                <h3 className='card-title text-bold MuiTableCell-head'>Volume of assets per product category</h3>
              </div>
              <div className='card-body'>
                <div>
                  {
                    productGroup?.length > 0 ? <Column {...productGroupconfig} /> :
                      <div><span>No records found.</span></div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </>
)

export default function OverviewWrapper() {
  const [totalAssets] = useState(0)
  const [upcomingrents, setUpcomingrents] = useState([])
  const [totalAssetsList, setTotalAssetsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [agreementList, setAgreementList] = useState([])
  const [agreementListTemp, setAgreementListTemp] = useState([])
  const [agreementListTotal, setAgreementListTotal] = useState(0)
  const { data } = useSelector(({ auth }) => auth.user);
  const [totalFinanceAggTitle, setTotalFinanceAggTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState([])
  const [upComingBarChart, setUpComingBarChart] = useState([])

  const [userInfo, setUserInfo] = useState(data);
  const dispatch = useDispatch()
  const [agreementDetails, setAgreementDetails] = useState({
    noofagreements: 0,
    nextEndingDate: 0,
    monthyRent: 0,
    QuarterlyRent: 0,
    YearlyRent: 0,
    totalValue: 0
  })
  const [clientsValues, setClientsValues] = React.useState([]);
  const [partnersValues, setPartnersValues] = React.useState([]);
  const [values, setValues] = useState({
    partner: null,
    client: null,
    aggrement: null
  })
  const [selected, setSelected] = useState([]);
  const [productGroup, setProductGroup] = useState([]);
  const [userTypes, setUserTypes] = useState({
    isClient: false,
    isAdmin: false,
    isPartner: false
  });
  const [top3Products, setTop3Products] = useState([]);
  const [filterAgreements, setFilterAgreements] = useState(null);
  const [resultsFound, setresultsfound] = useState(null);
  const [page, setPage] = useState('')
  const location = useLocation();

  useEffect(() => {
    if (location) {
      let currentpage = location.pathname.split("/")
      setPage(currentpage[1])
    }
  }, [location]);

  useEffect(() => {
    if (data && data?.id && page) {
      localStorage.setItem("globalsearchField", '')
      localStorage.setItem("globalsearchAgreements", '')

      if (userInfo && ((userInfo?.role?.name === 'client_admin') || (userInfo?.role?.name === 'client_user'))) {
        setTotalFinanceAggTitle('Rent over time')
        setUserTypes({
          isClient: true,
          isAdmin: false,
          isPartner: false
        })

      } else if (userInfo && ((userInfo?.role?.name === 'partner_admin') || (userInfo?.role?.name === 'partner_user'))) {
        setTotalFinanceAggTitle('Rent over time')
        setUserTypes({
          isClient: false,
          isAdmin: false,
          isPartner: true
        })

      } else {
        setTotalFinanceAggTitle('Rent over time')
        setUserTypes({
          isClient: false,
          isAdmin: true,
          isPartner: false
        })

      }
      if (data?.role?.name) {
        setUserInfo(data)
        setLoading(true)
        // listsAllAgreement()
        if (data?.role.name === 'client_admin' || data?.role.name === 'client_user') {
          // listsAllAgreement([],null,null, data?.client?.id);

          getClientList(null, data?.client?.id)
          return
        }
        else if (data?.role?.name === 'partner_admin' || data?.role?.name === 'partner_user') {
          listsAllAgreement([], null, data?.partner?.id, null);
          getClientList(data?.partner?.id, null, null)
          return
        } else {
          getClientList(null, null, 'admin')
          listsAllAgreement();
          return
        }
        // callAssetsList()
        // if (data?.role?.name !== 'master_admin') {
        //   callupcomingrents();
        // }
      } else {
        logout()
      }

    }
  }, [data, page])

  const getClientList = (partnerId = null, client = null, type = null) => {
    let queryFields = 'client.name,client.id,client.clients_org_no,partner.partner_org_no,partner.partner_name,partner.id'
    let filter = ''
    if (partnerId) {
      filter = `?filter[partner][_eq]=${partnerId}&fields=`
    }
    if (client) {
      filter = `?filter[client][_eq]=${client}&fields=`
    }
    ClientAddessList(queryFields, filter)
      .then(({ data: { data } }) => {

        if (data && data.length > 0) {
          if (!partnerId) {
            let partners = data.map(
              (item) => item.partner && item.partner
            );
            partners = partners.filter(function (e) { return e != null; });
            partners = _.orderBy(partners, ['partner_name'], ['asc']);
            setPartnersValues(_.uniqBy(partners, 'id'))
            if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {

              let multiplepartner = data.map((item) => item.partner.id)
              listsAllAgreement([], null, multiplepartner)
            }
          }
          if (partnerId || client) {
            // values.partner = "";
            let clients = data.map(
              (item) => item.client
            );
            clients = _.orderBy(clients, ['name'], ['asc']);
            setClientsValues(_.uniqBy(clients, 'id'))
            // if (client) {
            //   listsAllAgreement(client)
            // }
          }
        }
      })
      .catch((err) => {
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  const logout = () => {
    dispatch(auth.actions.logout())
    {
      (localStorage.getItem("userType") === 'PARTNER' ||
        localStorage.getItem("userType") === 'CLIENT') ? <Redirect to='/login' /> : <Redirect to='/auth/login' />
    }
  }

  useEffect(() => {
    if (selectedRows && agreementList) {
      if (selectedRows.length > 0) {
        let ids = (selectedRows.map((item) => item.id))
        callAssetsList(agreementListTemp, ids, values)
        setSelected(ids)
      } else {
        let ids = (agreementList.map((item) => item.id))
        setSelected(ids)
      }
    }
  }, [selectedRows, agreementList])

  useEffect(() => {
    if (values.partner || values.client) {
      // console.log("calll asseetss")
      // await callAssetsList(agreementList)
      const asyncFn = async () => { callAssetsList(agreementListTemp) };
      asyncFn();
    } else if (!values.partner && !values.client) {
      // console.log(selectedRows, "selected", selected)
      // callAssetsList(agreementList)
      // const asyncFn = async () => { callAssetsList(agreementList) };
      // asyncFn();
    }
  }, [values])

  const getValues = async (partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, upcomingresponse) => {
    setLoading(false)
    let vals = await getAgValuesFormat(partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, userTypes, page, upcomingresponse, values)
    setAgreementList(vals.agreementList);
    // setUpComingBarChart(vals.upComingBarChart);
    setProductGroup(vals.productGroup);
    setAgreementDetails(vals.agreementDetails);
    setTop3Products(vals.top3products);
  }

  const search = async (event) => {
    // console.log("valuessssssssssss", event.target.searchfield.value)
    event.preventDefault();
    if (!values.searchfield) {
      localStorage.setItem("globalsearchField", '')
      localStorage.setItem("globalsearchAgreements", '')

      setValues({ ...values, aggrement: [] })
      setFilterAgreements([])
      listsAllAgreement([])
      return;
    }
    let string = values.searchfield;
    let options = {
      limit: -1,
      fields: [
        `aggrement`,
      ],
      groupBy: ['aggrement'],
      filter: {
        _and: []
      }
    }
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        options.filter['_and'].push({
          _and:
            [{
              "aggrement": {
                "client": {
                  _eq: userInfo.client.id
                }
              }
            }]
        })
      } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        options.filter['_and'].push({
          _and:
            [{
              "aggrement": {
                "partner": {
                  _eq: userInfo.partner.id
                }
              }
            }]
        })
      }
    }
    options.filter['_and'].push({
      "aggrement": {
        "ag_status": {
          _in: FILTER_AG_STATUS
        }
      }
    })
    string = string.split(" ");
    let filters = {
      _and: []
    }
    string.map((obj) => {
      filters['_and'].push({
        "searchfield": {
          _contains: `-${obj.toLowerCase()}`
        }
      })
    })
    options.filter['_and'].push(filters)
    const result = await directUSAPICall().items('Asset').readMany(options);
    if (result.data && result.data.length > 0) {
      localStorage.setItem("globalsearchField", values.searchfield)

      let tempselected = result.data.map(
        (item) => item.aggrement
      );
      setFilterAgreements(tempselected);
      localStorage.setItem("globalsearchAgreements", tempselected.toString())

      setresultsfound('true')
    } else {
      localStorage.setItem("globalsearchField", '')
      localStorage.setItem("globalsearchAgreements", '')
      setresultsfound('false')
      setValues({ ...values, searchfield: '' })
      listsAllAgreement([], 'reset')

      setFilterAgreements([])
    }
  }
  useEffect(() => {
    if (values.searchfield && filterAgreements?.length > 0) {
      listsAllAgreement(filterAgreements)
    }
  }, [filterAgreements, values.searchfield])

  const listsAllAgreement = async (searchedAgreemets = [], searchfield = null, multiplepartner = []) => {
    setLoading(true)
    let dashboardfields = [
      'ag_status',
      'heading',
      'agrement_info_no',
      'eol_date',
      'start_date',
      'no_of_month_lease',
      'sum_purchase_value',
      'lease_start_date',
      'type_of_product',
      'sum_purchase_value_1',
      'montly_payment_rent_or_lease',
      'id',
      'client',
      'partner',
      'client.id',
      'client.name',
      'client.clients_org_no',
      'partner.id',
      'partner.partner_org_no',
      'partner.partner_name',
      'Interim_income',
      'EOL_value_amt'
    ]

    let options = {
      limit: -1,
      sort: '-id',
      filter: {
        _and: []
      }
    }
    let agfilters = []

    if (userInfo?.role?.name) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        options.filter = {
          _and:
            [
              {
                "client.id": {
                  _in: userInfo.client.id
                },
                "ag_status": {
                  _in: FILTER_AG_STATUS
                }
              }
            ]
        }
      }
      if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        options.filter = {
          _and:
            [
              {
                "partner.id": {
                  _eq: userInfo.partner.id
                },
                "ag_status": {
                  _in: FILTER_AG_STATUS
                }
              }
            ]
        }
        if (values.client) {
          options.filter['_and'].push({
            "client.id": {
              _eq: values.client
            }
          })
        }



      } else {
        //chekc client own agreement

        // options.filter['_and'].push({
        //   "isclientownaggreement": {
        //     _eq: isOwnOverview === 'true' ? true : false
        //   }
        // })
      }

      if ((userInfo?.userType !== "SUPERADMIN" || userInfo?.userType !== "ADMINUSER") && values.searchfield) {
        let tempselected = []
        if (selected && selected.length > 0) {
          tempselected = selected.map(
            (item) => item.id
          );
          // values.aggrement = tempselected
        }

        if (selected.length === 0 && (searchedAgreemets && searchedAgreemets.length > 0)) {
          values.aggrement = searchedAgreemets
        } else if (selected.length === 0 && (searchedAgreemets && searchedAgreemets.length === 0)) {
          values.aggrement = null;
        } else if (selected.length > 0 && searchedAgreemets && searchedAgreemets.length === 0) {
          values.aggrement = tempselected
        } else if (selected.length > 0 && searchedAgreemets && searchedAgreemets.length > 0) {
          values.aggrement = [...tempselected, ...searchedAgreemets]
        }
        if (values.aggrement) {
          options.filter['_and'].push({
            "id": {
              _in: values.aggrement
            }
          })
        }

        let assetfields = ["assets.id,assets.aggrement,assets.user_name,assets.price_total,assets.rent_period,assets.dailyrent,assets.searchfield"]
        dashboardfields = [...dashboardfields, ...assetfields]
        //   //console.log("dashboardfields1",dashboardfields1)
        agfilters = values.searchfield.toLowerCase().replace(" ", "-");

        let string = values.searchfield.split(" ");
        let filters = {
          _and: []
        }
        string.map((obj) => {
          filters['_and'].push({
            // "searchfield": {
            //   _contains: `-${obj.toLowerCase()}`
            // }

            "assets": {
              "searchfield": {
                _contains: `-${obj.toLowerCase()}`
              }
            }

          })
        })

        // console.log(filters, "filters", options.filter)
        //   //{"assets":{"searchfield":{"_contains":"-henri"}}},{"assets":{"searchfield":{"_contains":"-ansi"}}}
        // options.filter['_and'] =filters['_and'] ? [...options.filter['_and'], ...filters['_and']] : [...filters['_and']]
      }

      options.fields = dashboardfields.toString();

    }
    let param = `?limit=-1&sort=-id&fields=${options.fields}&filter=${JSON.stringify(options.filter)}`


    getLists('aggrement', param)
      .then(async ({ data: { data } }) => {
        if (data && data.length > 0) {
          // await getagreementDetails(result.data)
          setAgreementList(data)
          // await getValues(values.partner, values.client, result.data, [], selected)
          if (selected && selected.length === 0) {
            if ((userInfo?.userType !== "SUPERADMIN" || userInfo?.userType !== "ADMINUSER") && values.searchfield) {
              data.map((item) => {
                let totalAssetsValues = 0
                if (item.assets) {

                  item.assets.map((obj) => {
                    let searchValues = []
                    if (obj.searchfield) {
                      // console.log(obj.searchfield,"check strngss", agfilters);
                      if (obj.searchfield.indexOf("-" + agfilters) !== -1) {
                        totalAssetsValues += parseFloat(obj.price_total)
                        // totalAssetsValues = _.round(_.sumBy(tempAssets, x => x.sum.price_total ? (parseFloat(x.sum.price_total)) : 0), 0)
                      }
                    }
                  })
                  // item.sum_purchase_value
                  item.sum_purchase_value = totalAssetsValues
                }
              })
            }
            setAgreementListTemp(data);
          }
          setAgreementListTotal(data.length)
          await callAssetsList(data, filterAgreements, searchfield)
        } else {
          setAgreementList([])
          setAgreementListTotal(0)
          setLoading(false)
          //   setStatus('The login detail is incorrect')
        }

      })
      .catch((err) => {
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
    return
    const result = await directUSAPICall().items('aggrement').readMany(options);


  }

  const callAssetsList = async (aggrementtemp, selectedAgreements = null, searchfield) => {
    setLoading(true)
    let options = {
      limit: -1,
      sort: 'product_group',
      fields: [
        `product_group,price_total,dailyrent`,
      ],
      groupBy: ['product_group'],
      aggregate: {
        sum: 'price_total,price_total,dailyrent'
      },
      filter: {
        _and: [
          {
            "aggrement": {
              "status": {
                _nnull: true
              }
            }
          },
          {
            "priceper_unit": {
              _nnull: true
            }
          }
        ]
      }
    }

    let ag_ids = selectedAgreements ? selectedAgreements : selectedRows.map((e) => e.id);
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        if (ag_ids && ag_ids.length > 0) {
          values.client = userInfo.client.id
        } else {
          options.filter['_and'].push({
            "aggrement": {
              "client": {
                _eq: userInfo.client.id
              }
            }
          })
        }
      } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        // console.log("users_details", users_details)

        if (ag_ids && ag_ids.length > 0) {
          values.partner = userInfo.partner.id;
        } else {
          options.filter['_and'].push({
            "aggrement": {
              "partner": {
                _eq: userInfo.partner.id
              }
            }
          })
        }
      }
      if (values.client && !values.partner && ag_ids.length === 0) {
        options.filter['_and'].push({
          "aggrement": {
            "client": {
              _eq: values.client
            }
          }
        })

      }
      if (values.partner && !values.client && ag_ids.length === 0) {

        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        })

      }

      if (values.partner && values.client && ag_ids.length === 0) {
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement": {
              "client": {
                _eq: values.client
              }
            }
          })
      }
      if (values.partner && values.client && ag_ids && ag_ids.length > 0) {
        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement": {
              "client": {
                _eq: values.client || null
              }
            }
          },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })

      }
      if (values.partner && !values.client && ag_ids && ag_ids.length > 0) {
        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })

      }
      if (!values.partner && values.client && ag_ids && ag_ids.length > 0) {
        // let tempselected = selected.map(
        //   (item) => item.id
        // );

        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "client": {
              "_eq": values.client
            }
          }
        },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })
      }
      if (!values.partner && !values.client && ag_ids?.length > 0) {
        values.aggrement = ag_ids
        options.filter = {
          _and: [
            {
              "aggrement.id": {
                _in: ag_ids
              }
            }
          ]
        }
      }
      // //Only show 
      let filterStatus = FILTER_ASSET_STATUS;
      if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        filterStatus = FILTER_AG_STATUS
      }

      options.filter['_and'].push({
        "aggrement": {
          "ag_status": {
            _in: filterStatus
          }
        }
      })
      // options.filter['_and'].push({
      //   "isclientownasset": {
      //     _eq: isOwnOverview === 'true' ? true : false
      //   }
      // })

    }
    if ((userInfo?.userType !== "SUPERADMIN" || userInfo?.userType !== "ADMINUSER") && values.searchfield && searchfield === null) {
      let string = values.searchfield.split(" ");
      let filters = {
        _and: []
      }
      string.map((obj) => {
        filters['_and'].push({
          "searchfield": {
            _contains: `-${obj.toLowerCase()}`
          }
        })
      })
      options.filter['_and'] = options.filter['_and'] ? [...options.filter['_and'], ...filters['_and']] : [...filters['_and']]
    }

    setLoading(true);

    const result = await directUSAPICall().items('Asset').readMany(options);

    if (result.data && result.data.length > 0) {
      let response = result.data;
      let total = 0;
      // let rents_upcoming = [];
      // console.log("totalAssetsList", totalAssetsList)
      total = response.reduce(function (prev, current) {
        return prev + +current.totalassets
      }, 0);
      // await getValues(values.partner, values.client, aggrementtemp, response, ag_ids, [], searchfield)

      // console.log("insiseee")
      await callUpcomingAssetsList(aggrementtemp, response, ag_ids, searchfield, options)

      // setLoading(false)
      setTotalAssetsList(response);
      // setLoading(false)
      // AggByPartner()
    }
    else {
      await getValues(values.partner, values.client, aggrementtemp, [], ag_ids, [])
      setLoading(false)
      setUpComingBarChart([]);
      setProductGroup([]);
      // setAgreementDetails({
      //   noofagreements: 0,
      //   nextEndingDate: 0,
      //   monthyRent: 0,
      //   QuarterlyRent: 0,
      //   YearlyRent: 0,
      //   totalValue: 0
      // });
      setTop3Products([]);
    }

  }

  const callUpcomingAssetsList = async (aggrementtemp, assetdata, ag_ids, searchfield) => {
    setLoading(true);
    let options = {
      filter: {
        _and: [
          {
            "aggrement": {
              "status": {
                _nnull: true
              }
            }
          }
        ]
      }
    }
    options.limit = 4
    options.fields = ['year(to_date)'];
    options.groupBy = ['year(to_date)'];
    options.aggregate = { sum: 'rentamount' }
    options.filter['_and'].push({
      "year(to_date)": {
        _gte: moment().format('YYYY')
      }
    }, {
      "aggrement": {
        "status": {
          _nnull: true
        }
      }
    })
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        if (ag_ids && ag_ids.length > 0) {
          values.client = userInfo.client.id
        } else {
          options.filter['_and'].push({
            "aggrement": {
              "client": {
                _eq: userInfo.client.id
              }
            }
          })
        }
      } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        // console.log("users_details", users_details)

        if (ag_ids && ag_ids.length > 0) {
          values.partner = userInfo.partner.id;
        } else {
          options.filter['_and'].push({
            "aggrement": {
              "partner": {
                _eq: userInfo.partner.id
              }
            }
          })

        }

      }
      if (values.client && !values.partner && ag_ids.length === 0) {
        options.filter['_and'].push({
          "aggrement": {
            "client": {
              _eq: values.client
            }
          }
        })

      }
      if (values.partner && !values.client && ag_ids.length === 0) {

        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        })

      }

      if (values.partner && values.client && ag_ids.length === 0) {
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement": {
              "client": {
                _eq: values.client
              }
            }
          })
      }
      if (values.partner && values.client && ag_ids && ag_ids.length > 0) {
        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement": {
              "client": {
                _eq: values.client || null
              }
            }
          },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })

      }
      if (values.partner && !values.client && ag_ids && ag_ids.length > 0) {
        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "_eq": values.partner
            }
          }
        },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })

      }
      if (!values.partner && values.client && ag_ids && ag_ids.length > 0) {
        // let tempselected = selected.map(
        //   (item) => item.id
        // );

        values.aggrement = ag_ids
        options.filter['_and'].push({
          "aggrement": {
            "client": {
              "_eq": values.client
            }
          }
        },
          {
            "aggrement.id": {
              _in: ag_ids
            }
          })
      }
      if (!values.partner && !values.client && ag_ids?.length > 0) {
        values.aggrement = ag_ids
        options.filter = {
          _and: [
            {
              "aggrement.id": {
                _in: ag_ids
              }
            }
          ]
        }
      }
      // //Only show 
      let filterStatus = FILTER_ASSET_STATUS;
      if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        filterStatus = FILTER_AG_STATUS
      }

      options.filter['_and'].push({
        "aggrement": {
          "ag_status": {
            _in: filterStatus
          }
        }
      })
      // options.filter['_and'].push({
      //   "isclientownasset": {
      //     _eq: isOwnOverview === 'true' ? true : false
      //   }
      // })

    }
    if ((userInfo?.userType !== "SUPERADMIN" || userInfo?.userType !== "ADMINUSER") && values.searchfield && searchfield === null) {
      let string = values.searchfield.split(" ");
      let filters = {
        _and: []
      }
      string.map((obj) => {
        filters['_and'].push({
          "searchfield": {
            _contains: `-${obj.toLowerCase()}`
          }
        })
      })
      options.filter['_and'] = options.filter['_and'] ? [...options.filter['_and'], ...filters['_and']] : [...filters['_and']]
    }
    const result = await directUSAPICall().items('upcoming').readMany(options);

    if (result.data && result.data.length > 0) {
      result.data.forEach((item) => {
        // item.year = item.to_date_year
        item.value = Math.round(item.sum.rentamount)
      })
      let upcomingresponse = result.data;

      setUpcomingrents(upcomingresponse)
      setUpComingBarChart(result.data);
      await getValues(values.partner, values.client, aggrementtemp, assetdata, ag_ids, upcomingresponse, searchfield)
      setLoading(false)
      // getClientList(partnerId,clientId)
    }
    else {
      // getClientList(partnerId,clientId)
      setUpComingBarChart([]);
      setLoading(false)
      // await getValues(values.partner, values.client, aggrementtemp, assetdata, ag_ids, [], searchfield)

    }

  }

  // const callUpcomingAssetsList = async (aggrementtemp, assetdata, ag_ids, searchfield,options) => {
  //   let queryParam = ``
  //   // if (userInfo.role) {
  //   //   if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
  //   //     queryParam = `?client=${userInfo.client.id}`;
  //   //   }
  //   //   if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
  //   //     queryParam = `?partner=${userInfo.partner.id}`;
  //   //   }
  //   // }
  //   let AGIDS = aggrementtemp.map(
  //     (item) => item.id
  //   );
  //   // console.log(AGIDS,"AGIDS",ag_ids)
  //   if (ag_ids.length > 0) {
  //     queryParam = `${ag_ids}`
  //   } else {
  //     queryParam = `${AGIDS}`
  //   }

  //   setLoading(true);
  //   let searchedValue = !searchfield ? values.searchfield : ''
  //   getDashboardAssets(queryParam, searchedValue)
  //     .then(async ({ data: { data } }) => {
  // if (data && data.length > 0) {
  //   let upcomingresponse = data;
  //   console.log("upcomingresponse", upcomingresponse)
  //   setUpcomingrents(upcomingresponse)
  //   await getValues(values.partner, values.client, aggrementtemp, assetdata, ag_ids, upcomingresponse, searchfield)
  //   setLoading(false)
  //   // AggByPartner()
  // }
  // else {
  //   setLoading(false)
  //   await getValues(values.partner, values.client, aggrementtemp, assetdata, ag_ids, [], searchfield)

  // }
  //     })
  //     .catch(() => {
  //       // setLoading(false)
  //       //   setStatus('The login detail is incorrect')
  //     })

  // }

  const productGroupconfig = {
    data: productGroup.sort((a, b) => (a.name) - (b.name)),
    xField: 'name',
    yField: 'value',
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        window.open(`/assetview/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
      plot.on('element:hover', (...args) => {
        window.open(`/assetview/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
    },

    cursor: 'pointer',
    xAxis: {
      label: {
        style: {
          fontSize: 10,
          fontWeight: 600,
        },
        autoRotate: false,
        autoHide: true,
      },
    },
    label: {
      position: 'top',
      style: {
        fontSize: 10,
        fontWeight: 900,
      },
      content: (originData) => {
        return `${SVformatter(originData.value)} kr`;
      },
    },
    yAxis: {
      label: {
        style: {
          fill: 'black',
        },
        offset: 15,
        formatter: (text) => {
          return `${DataFormater(text)}`;
        },
      },
    },
    columnWidthRatio: 0.8,
    minColumnWidth: 60,
    maxColumnWidth: 80,
    autoFit: true,
    appendPadding: [17, 0, 0, 0],
    color: '#456A76',
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.name} `,
        value: `${SVformatter(datum.value)} kr`,
      }),
    },
  };


  const upComingBarChartconfig = {
    data: upComingBarChart.sort((a, b) => (a.year) - (b.year)),
    xField: 'to_date_year',
    yField: 'value',
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        window.open(`/agreementsview/${args[0].data.data.to_date_year}`, '_blank', 'noreferrer');
      });
    },
    cursor: 'pointer',

    xAxis: {
      label: {
        style: {
          fontSize: 10,
          fontWeight: 600,
        },
        autoRotate: false,
        autoHide: true,
      },

    },
    label: {
      position: 'top',
      style: {
        fontSize: 10,
        fontWeight: 900,
      },

      content: (originData) => {
        return `${SVformatter(originData.value)} kr`;
      },

    },

    yAxis: {

      label: {
        style: {
          fill: 'black',
        },
        offset: 15,
        formatter: (text) => {
          return `${DataFormater(text)}`;
        },
      },
    },
    columnWidthRatio: 0.8,
    minColumnWidth: 60,
    maxColumnWidth: 80,
    autoFit: true,
    appendPadding: [17, 0, 0, 0],
    color: '#456A76',
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.to_date_year} `,
        value: `${SVformatter(datum.value)} kr`,
      }),
    },
  };


  const pieconfig = {
    data: top3Products || [],
    angleField: 'value',
    colorField: 'name',
    innerRadius: 0.6,
    padding: 40,
    label: {
      content: (d) => `${SVformatter(d.value)} kr`,
      position: 'spider',
    },
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        window.open(`/assetview/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
    },
    cursor: 'pointer',
    legend: { layout: 'horizontal', position: 'bottom' },
    statistic: false,
    colorField: 'name', // or seriesField in some cases
    color: ["#a95c24", "#456A76", "#808080"],
    // render: customLabel,
  };


  return (
    <div className="overview-container">
      <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
      <div>
        <FilterSelection
          userRole={userInfo?.role?.name}
          agreementListTemp={agreementListTemp}
          agreementList={agreementList}
          clientsValues={clientsValues}
          partnersValues={partnersValues}
          selected={selected}
          title="Overview"
          setValues={setValues}
          values={values}
          getClientList={getClientList}
          userTypes={userTypes}
          totalAssetsList={totalAssetsList}
          getValues={getValues}
          setSelected={setSelected}
          upcomingrents={upcomingrents}
          search={search}
          resultsFound={resultsFound}
        />
      </div>
      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
      {/* {productGroup?.length > 0 && <DemoColumn />} */}
      <DashboardPage
        agreementList={agreementList}
        agreementListTemp={agreementListTemp}
        agreementListTotal={agreementListTotal}
        userDetail={userInfo}
        totalAssets={totalAssets}
        productGroup={productGroup}
        upComingBarChart={upComingBarChart}
        top3Products={top3Products}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        agreementDetails={agreementDetails}
        totalAssetsList={totalAssetsList}
        upcomingrents={upcomingrents}
        totalFinanceAggTitle={totalFinanceAggTitle}
        values={values}
        getValues={getValues}
        setLoading={setLoading}
        callAssetsList={callAssetsList}
        productGroupconfig={productGroupconfig}
        upComingBarChartconfig={upComingBarChartconfig}
        setSelected={setSelected}
        userTypes={userTypes}
        pieconfig={pieconfig}
      />

    </div>
  )
}

export { OverviewWrapper }
